import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import AuthenticatedRoute from './auth/AuthenticationRoute';
// import './scss/style.scss';
// import './scss/_variables.scss';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/Login'));
const Register = React.lazy(() => import('./views/pages/Register'));
const Page404 = React.lazy(() => import('./views/pages/Page404'));
const Page500 = React.lazy(() => import('./views/pages/Page500'));

class App extends Component {

  render() {
    return (
      <HashRouter>
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
          <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
          <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
          <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
          <AuthenticatedRoute path="/" name="Home" render={props => <TheLayout {...props} />} />
          <Route component={Page404} />
        </Switch>
      </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;


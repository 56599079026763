//const ADMIN_APIS_URL = "http://localhost:8080/api/sales";
//const ADMIN_BASE_URL = "http://localhost:8080/";
const ADMIN_APIS_URL = "https://admapis.callupapp.co/api/sales";
const ADMIN_BASE_URL = "https://admapis.callupapp.co/";
const ADMIN_LOCATION_URL = "https://admapis.callupapp.co";

const DAILY_CAP_TRIGGER_OPTIONS = [
    { 'id': '', 'title': 'Choose...' },
    { 'id': 'Links Clicked', 'title': 'Click' },
    { 'id': 'Postback', 'title': 'Postback' },
    { 'id': 'Pixel', 'title': 'Pixel' },
    { 'id': 'Manual uploads', 'title': 'Manual uploads' },
    { 'id': 'Add Sales', 'title': 'Add Sales' }
]

const TRACKING_METHOD_OPTIONS = [
    { 'id': '', 'title': 'Choose...' },
    { 'id': 'Postback', 'title': 'Postback' },
    { 'id': 'Pixel', 'title': 'Pixel' },
    { 'id': 'Manual uploads', 'title': 'Manual uploads' },
    { 'id': 'Add Sales', 'title': 'Add Sales' }
]

const MONTHS = [
    { 'id': '01', 'title': 'Jan' },
    { 'id': '02', 'title': 'Feb' },
    { 'id': '03', 'title': 'Mar' },
    { 'id': '04', 'title': 'Apr' },
    { 'id': '05', 'title': 'May' },
    { 'id': '06', 'title': 'Jun' },
    { 'id': '07', 'title': 'Jul' },
    { 'id': '08', 'title': 'Aug' },
    { 'id': '09', 'title': 'Sep' },
    { 'id': '10', 'title': 'Oct' },
    { 'id': '11', 'title': 'Nov' },
    { 'id': '12', 'title': 'Dec' },
]

const YEARS = [
    { 'id': '2021', 'title': '2021' },
    { 'id': '2022', 'title': '2022' },
    { 'id': '2023', 'title': '2023' },
    { 'id': '2024', 'title': '2024' },
    { 'id': '2025', 'title': '2025' },
    { 'id': '2026', 'title': '2026' },
    { 'id': '2027', 'title': '2027' },
    { 'id': '2028', 'title': '2028' },
    { 'id': '2029', 'title': '2029' },
    { 'id': '2030', 'title': '2030' },
]


const PRODUCT_SELECT_LIMIT = 200;
const DEFAULT_DROPDOWN_ITEMS_LIMIT = 200;


const RESOURCES_BASE_URL = "https://rocket-singh-uat.s3.ap-south-1.amazonaws.com/APP_RESOURCES/";
const S3_BASE_URL = "https://rocket-singh-uat.s3.ap-south-1.amazonaws.com";

export const constants = {
    ADMIN_APIS_URL,
    ADMIN_BASE_URL,
    ADMIN_LOCATION_URL,
    DAILY_CAP_TRIGGER_OPTIONS,
    MONTHS,
    PRODUCT_SELECT_LIMIT,
    TRACKING_METHOD_OPTIONS,
    YEARS,
    RESOURCES_BASE_URL,
    DEFAULT_DROPDOWN_ITEMS_LIMIT,
    S3_BASE_URL
};

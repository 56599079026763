import axios from 'axios';
import { constants } from '../constants';


class AuthenticationService {

  login(loginRequest) {
    console.log(constants.ADMIN_BASE_URL);
    return axios
      .post(constants.ADMIN_BASE_URL + "auth/signin", loginRequest)
      .then(response => {
        console.log(response)
        if (response.data) {
          sessionStorage.setItem("user", JSON.stringify(response.data));
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response;
      });
  }
  

  logout() {
    sessionStorage.removeItem('user')
    localStorage.removeItem('user')
    return true
  }
  
  isUserLoggedIn() {
    let user = JSON.parse(localStorage.getItem('user'))
    if (user === null) {
      return false
    }else{
       if (Date.now() >= user.expiry) {
        sessionStorage.removeItem('user')
        localStorage.removeItem('user')
        return false;
      }
       return true
    }
  }
  getLoggedInUser() {
    let user = localStorage.getItem('user')
    if (user === null) return ''
    return user
  }
  getLoggedInUserRole() {
    let user = JSON.parse(localStorage.getItem('user'))
    if (user === null) return ''
    return user.role;
  }

  getLoggedInUserName() {
    let user = JSON.parse(localStorage.getItem('user'))
    if (user === null) return ''
    return user.username;
  }


}
export default new AuthenticationService()